import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/WK/Private/Projects/Gatsby/MKF/src/templates/Page.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Box = makeShortcode("Box");
const Typography = makeShortcode("Typography");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Box mdxType="Box">
  <div style={{
        backgroundColor: '#fff',
        padding: '32px 64px',
        margin: '32px 0'
      }}>
    <div style={{
          margin: '64px 0',
          width: '100%'
        }}>
      <Typography variant={'h3'} renderAs={'h1'} mdxType="Typography">
        Impressum
      </Typography>
    </div>
    <div>
      <Typography paragraph variant={'h4'} renderAs={'h2'} mdxType="Typography">{`Hauptgeschäftsstelle: `}</Typography>
      <br />
      <Typography paragraph variant={'h5'} renderAs={'h3'} mdxType="Typography">
        MKF Mobiler Kranken Fahrservice
      </Typography>
      <p>{`Auf der Mainhöhe 6`}</p>
      <p>{`65451 Kelsterbach`}</p>
      <p>{`Tel: 0800 883 77 26`}</p>
      <p>{`Fax: 06172 5971910`}</p>
      <p>{`E-Mail: info@mkf-service.com`}</p>
      <p>{`Steuernummer: 21 816 01004`}</p>
      <br />
      <Typography paragraph variant={'h4'} renderAs={'h2'} mdxType="Typography">{`Zweigstellen: `}</Typography>
      <br />
      <Typography paragraph variant={'h5'} renderAs={'h3'} mdxType="Typography">{`MKF Mobiler Kranken Fahrservice - Niederstedter Weg`}</Typography>
      <p>{`Niederstedter Weg 11`}</p>
      <p>{`61348 Bad Homburg vor der Höhe`}</p>
      <p>{`Tel: 06172 9819814`}</p>
      <br />
      <Typography paragraph variant={'h5'} renderAs={'h3'} mdxType="Typography">{`MKF Mobiler Kranken Fahrservice - Mainzer Landstraße`}</Typography>
      <p>{`Mainzer Landstraße 351 - 353`}</p>
      <p>{`60326 Frankfurt am Main`}</p>
      <p>{`Tel: 069 34877514`}</p>
      <br />
    </div>
  </div>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      